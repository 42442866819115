import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Icons from "./../AuditUi/Icons/Icons.js";
import Accordion from "./../AuditUi/Accordion.js";
import Modalright from "./../AuditUi/FilterModal.js";
import classnames from "classnames";
import Link from "next/link";
import trim_tree from "plant_variables/trim_tree.json";
import useAppContext from "context/state";
import Skeleton from "react-loading-skeleton";
import ReactSlidingPane from "react-sliding-pane";
import DatePickerCalendar from "components/MainContent/DatePickerComponent";
import moment from "moment-timezone";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
  Row,
  Col,
  FormGroup,
  UncontrolledCollapse,
  Form,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useSession } from "next-auth/client";
import { Data } from "@react-google-maps/api";

// const unixepochToDateObj = (number) => {
//   //var s = new Date(number * 1000);
//   var s = moment.unix(number).toDate();
//   if (number === undefined) s = new Date();

//   const obj = {
//     year: s.getFullYear(),
//     month: s.getMonth() + 1,
//     day: s.getDate(),
//   };
//   return obj;
// };

// const unixepochToDateObj = (number, timezone = 'Asia/Kolkata') => {
//   // console.log('Input number:', number);
//   // console.log('Input timezone:', timezone);

//   var s = moment.unix(number).tz(timezone);
//   // console.log('Moment object:', s);

//   const obj = {
//       year: s.year(),
//       month: s.month() + 1,
//       day: s.date(),
//   };
//   // console.log('Output object:', obj);

//   return obj;
// };

const unixepochToDateObj = (number, timezone = "Asia/Kolkata") => {
  // console.log('Input number:', number);
  // console.log('Input timezone:', timezone);

  var s;
  if (number === undefined) {
    s = moment().tz(timezone);
  } else {
    s = moment.unix(number).tz(timezone);
  }
  // console.log('Moment object:', s);

  const obj = {
    year: s.year(),
    month: s.month() + 1,
    day: s.date(),
  };
  // console.log('Output object:', obj);

  return obj;
};

const monthNames = {
  1: "JAN",
  2: "FEB",
  3: "MAR",
  4: "APR",
  5: "MAY",
  6: "JUN",
  7: "JUL",
  8: "AUG",
  9: "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC",
};

// const unixepochToDateString = (number) => {
//   let d1 = moment.unix(number);
//   var s = moment.unix(number).toDate();

//   const obj = {
//     year: s.getFullYear(),
//     month: s.getMonth() + 1,
//     day: s.getDate(),
//   };
//   return d1.date() + " " + monthNames[d1.month() + 1] + " " + d1.year();
// };

const unixepochToDateString = (number, timezone = "Asia/Kolkata") => {
  // console.log('Input number:', number);
  // console.log('Input timezone:', timezone);

  var s = moment.unix(number).tz(timezone);
  // console.log('Moment object:', s);

  const obj = {
    year: s.year(),
    month: s.month() + 1,
    day: s.date(),
  };
  // console.log('Output object:', obj);
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return obj.day + " " + monthNames[obj.month - 1] + " " + obj.year;
};

// const OverviewPageCard = ({ item, name }) => (
//   <>
//     <Card className="card-stats">
//       <CardBody style={{ textAlign: "center" }}>
//         <Row>
//           <div className="col">
//             <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
//               {item.isLoading ? (
//                 {/* <Skeleton width={"8rem"} height={"1rem"} count={1} /> */}
//               ) : (
//                 <>
//                   {name}({item.data.unit})
//                 </>
//               )}
//             </CardTitle>
//             <span className="h2 font-weight-bold mb-0">
//               {item.isLoading ? (
//                 {/* <Skeleton width={"3rem"} height={"1.5rem"} count={1} /> */}
//               ) : (
//                 item.data.present
//               )}
//             </span>
//           </div>
//         </Row>

//         {item.data.change >= 0 ? (
//           <p className="mt-3 mb-0 text-sm">
//             {item.isLoading ? (
//               {/* <Skeleton width={"3rem"} height={"1rem"} count={1} /> */}
//             ) : (
//               <span className="text-success mr-2">
//                 {item.data.change} {"%"}
//                 <i className="fa fa-arrow-up" />
//               </span>
//             )}{" "}
//             <span className="text-nowrap">
//               {item.isLoading ? (
//                 {/* <Skeleton width={"8rem"} height={"1rem"} count={1} /> */}
//               ) : (
//                 <>Since previous day</>
//               )}
//             </span>
//           </p>
//         ) : (
//           <p className="mt-3 mb-0 text-sm">
//             {item.isLoading ? (
//               {/* <Skeleton width={"3rem"} height={"1rem"} count={1} /> */}
//             ) : (
//               <span className="text-danger mr-2">
//                 {item.data.change} {"%"}
//                 <i className="fa fa-arrow-down" />
//               </span>
//             )}{" "}
//             <span className="text-nowrap">
//               {item.isLoading ? (
//                 {/* <Skeleton width={"8rem"} height={"1rem"} count={1} /> */}
//               ) : (
//                 <>Since previous day</>
//               )}
//             </span>
//           </p>
//         )}
//       </CardBody>
//     </Card>
//   </>
// );

const OverviewPageCard = ({ item, name }) => (
  <>
    <Card className="card-stats">
      <CardBody style={{ textAlign: "center" }}>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              <>
                {name}({item.data.unit})
              </>
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {item.data.present}
            </span>
          </div>
        </Row>

        {item.data.change >= 0 ? (
          <p className="mt-3 mb-0 text-sm">
            {
              <span className="text-success mr-2">
                {item.data.change} {"%"}
                <i className="fa fa-arrow-up" />
              </span>
            }{" "}
            <span className="text-nowrap">{<>Since previous day</>}</span>
          </p>
        ) : (
          <p className="mt-3 mb-0 text-sm">
            {
              <span className="text-danger mr-2">
                {item.data.change} {"%"}
                <i className="fa fa-arrow-down" />
              </span>
            }{" "}
            <span className="text-nowrap">{<>Since previous day</>}</span>
          </p>
        )}
      </CardBody>
    </Card>
  </>
);

const MeterDataReadingChecks = (props) => {
  return (
    <>
      <div
        style={{ display: "flex", marginTop: "1rem", paddingBottom: "0rem" }}
      >
        <label>Select Options:</label>
        {/*<div className=" custom-control custom-checkbox">*/}
        {/*  <input*/}
        {/*    className=" custom-control-input"*/}
        {/*    id="customCheck1"*/}
        {/*    type="checkbox"*/}
        {/*    onChange={props.handleClickCheckBox}*/}
        {/*    checked={props.twinReading}*/}
        {/*    name="twin"*/}
        {/*  ></input>*/}
        {/*  <label className=" custom-control-label" htmlFor="customCheck1">*/}
        {/*    Digital Twin*/}
        {/*  </label>*/}
        {/*</div>*/}
        {/*<div className=" custom-control custom-checkbox">*/}
        {/*  <input*/}
        {/*    className=" custom-control-input"*/}
        {/*    id="customCheck2"*/}
        {/*    type="checkbox"*/}
        {/*    onChange={props.handleClickCheckBox}*/}
        {/*    checked={props.globalReading}*/}
        {/*    name="global_reading"*/}
        {/*  ></input>*/}
        {/*  <label className=" custom-control-label" htmlFor="customCheck2">*/}
        {/*    Global Readings*/}
        {/*  </label>*/}
        {/*</div>*/}
        <div className=" custom-control custom-checkbox">
          <input
            className=" custom-control-input"
            id="customCheck3"
            type="checkbox"
            onChange={props.handleClickCheckBox}
            checked={props.normalizedReading}
            name="normalized"
          ></input>
          <label className=" custom-control-label" htmlFor="customCheck3">
            Actual/ Unnormalized
          </label>
        </div>
        {/*  <div className=" custom-control custom-checkbox">*/}
        {/*    <input*/}
        {/*      className=" custom-control-input"*/}
        {/*      id="customCheck4"*/}
        {/*      type="checkbox"*/}
        {/*      onChange={props.handleClickCheckBox}*/}
        {/*      checked={props.deviceState}*/}
        {/*      name="deviceState"*/}
        {/*    ></input>*/}
        {/*    <label className=" custom-control-label" htmlFor="customCheck4">*/}
        {/*      States*/}
        {/*    </label>*/}
        {/*  </div>*/}
      </div>
    </>
  );
};

function CardsHeader({
  name,
  parentName,
  meter,
  handleTicketDateChange,
  onModeChange,
  futureDateRangeChange,
  startDate,
  endDate,
  tableModeChange,
  overViewMode,
  peerSelection,
  powerCard,
  prCard,
  noiseTicketsFilter,
  closeTicketsFilter,
  noiseTicketsFilterAudit,
  closeTicketsFilterAudit,
  dataModeChange,
  noiseTickets,
  closedTickets,
  noiseTicketsAudit,
  closedTicketsAudit,
  peerList,
  meterMap,
  auditList,
  auditList_id,
  activeTabId,
  onFilterChange,
  onselectedAuditFromModel,
  report_title,
  ghiCard,
  giiCard,
  energyCard,
  ticketDate,
  handleClickCheckBox,
  twinReading,
  globalReading,
  normalizedReading,
  derivedReading,
  deviceState,
  plant,
  refreshIneterval,
  setRefreshInterval,
  lastRefresh,
  refreshPage,
  triggerRefresh,
  setTriggerRefresh,
  socCard,
  availabilityCard,
  frequencyCard,
  temperatureCard,
}) {
  const router = useRouter();
  const [selectedDay, setSelectedDay] = useState(
    unixepochToDateObj(ticketDate),
  );
  const [activeTab, setActiveTab] = useState("1");
  const api = useAppContext();

  // console.log("api>>>>>>",api)
  const [plantNameMap, setPlantNameMap] = useState({});

  React.useEffect(() => {
    var pnm = {};
    trim_tree["nodes"].forEach(function (item) {
      pnm[item.plant_id] = item.label;
    });

    setPlantNameMap(pnm);
  }, []);

  const [unitFilter, setUnitFilter] = useState("energyUnit");
  const [currencyFilter, setcurrencyFilter] = useState("INR");
  const [unit, setUnit] = useState("MWh");
  const [energyFilter, setEnergyFilter] = useState("MWh");
  const [periodFilter, setperiodFilter] = useState("weekly");
  const didMountRef = React.useRef(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const defaultRange = {
    from:
      typeof startDate === undefined
        ? unixepochToDateObj(api.startDate)
        : unixepochToDateObj(startDate),
    to:
      typeof endDate === undefined
        ? unixepochToDateObj(api.endDate)
        : unixepochToDateObj(endDate),
  };

  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);
  // console.log("selectedDayRange>>>>>>>>>>",selectedDayRange)
  const [selectedFutureDayRange, setSelectedFutureDayRange] =
    useState(defaultRange);
  // console.log("selectedFutureDayRange>>>>>>>>>",selectedFutureDayRange)

  const handleFutureDayRange = React.useCallback(() => {
    // console.log("ZZZZZZZZZZZZZZZZZZZZZZZZz")
    if (selectedFutureDayRange.to) {
      let start_date = new Date(
        selectedFutureDayRange.from.year +
          "-" +
          selectedFutureDayRange.from.month +
          "-" +
          selectedFutureDayRange.from.day,
      );
      let end_date = new Date(
        selectedFutureDayRange.to.year +
          "-" +
          selectedFutureDayRange.to.month +
          "-" +
          selectedFutureDayRange.to.day,
      );
      futureDateRangeChange(
        (start_date.getTime() / 1000).toFixed(0),
        (end_date.getTime() / 1000).toFixed(0),
      );
    }
  }, [selectedFutureDayRange]);

  //   const handleDaySelection = React.useCallback(() => {
  //     console.log("handleDaySelection called",selectedDay)
  //     let timezone = guessedTimeZone;
  //    let current_date = moment.tz(`${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`,'YYYY-MM-DD',timezone).startOf('day');
  //  api.onDateChange(
  //      (current_date.tz(timezone).unix()),
  //      (current_date.tz(timezone).unix())
  //  );

  //  router.push({
  //      pathname: router.pathname,
  //      query: {
  //          ...router.query,
  //          start_date: (current_date.tz(timezone).unix()),
  //          end_date: (current_date.tz(timezone).unix())
  //      },
  //  });

  // }, [selectedDay]);

  const guessedTimeZone = "Asia/Kolkata";

  const handleDaySelection = React.useCallback(() => {
    // console.log("handleDaySelection called")
    let timezone = guessedTimeZone;
    // let current_date = moment(
    //   `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
    // ).startOf("day");

    let current_date = moment
      .tz(
        `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`,
        "YYYY-MM-DD",
        timezone,
      )
      .startOf("day");

    console.log("CurrrentDate>>>>>>>>>>>", current_date);
    // api.onDateChange(current_date.unix(), current_date.unix());
    api.onDateChange(
      current_date.tz(timezone).unix(),
      current_date.tz(timezone).unix(),
    );

    // router.push({
    //   pathname: router.pathname,
    //   query: {
    //     ...router.query,
    //     start_date: current_date.unix(),
    //     end_date: current_date.unix(),
    //   },
    // });

    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        start_date: current_date.tz(timezone).unix(),
        end_date: current_date.tz(timezone).unix(),
      },
    });

    handleTicketDateChange(current_date.tz(timezone).unix());
  }, [selectedDay]);

  const selectfilterUnit = ({
    unitFilter,
    currencyFilter,
    periodFilter,
    energyFilter,
  }) => {
    setUnitFilter(unitFilter);
    setcurrencyFilter(currencyFilter);
    if (unitFilter === "currency") {
      setUnit(currencyFilter);
    } else if (unitFilter === "energyUnit") {
      setUnit(energyFilter);
    }
    setperiodFilter(periodFilter);
    onFilterChange({ unit, periodFilter });
  };

  const selectedAudit = (audit_id) => {
    onselectedAuditFromModel(audit_id);
  };
  const [session, loading] = useSession();
  const [isQuadrical, setIsQuadrical] = useState(
    session.user.email.includes("quadrical") ||
      (process.env.SUPER_USERS != undefined &&
        process.env.SUPER_USERS.split(",").includes(session.user.email)),
  );

  React.useEffect(() => {
    if (didMountRef.current) {
      if (["Operations", "plantAvailability"].includes(parentName)) {
        handleDaySelection();
      }
    } else didMountRef.current = true;
  }, [handleDaySelection]);

  const handleIconClick = () => {
    setTriggerRefresh(true);
  };

  return (
    <>
      <Col className="text-right" lg="2" xs="5">
        <>
          {parentName === "Overview" ? (
            <ReactSlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class"
              isOpen={api.paneState}
              title="DASHBOARD"
              subtitle={<span style={{ color: "gray" }}>CUSTOMIZATION</span>}
              width="20rem"
              from="right"
              closeIcon={<span style={{ fontSize: "50px" }}>&#8250;</span>}
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                api.setPaneState(false);
              }}
              shouldCloseOnEsc
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  api.setPaneState(false);
                }}
              >
                <FormGroup>
                  <div>
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="toggler"
                      type="button"
                    >
                      <h4>Compare Date Range</h4>
                    </button>
                    <UncontrolledCollapse toggler="#toggler" defaultOpen={true}>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="previous_day"
                            onChange={onModeChange}
                            checked={overViewMode === "previous_day"}
                          />
                          PREVIOUS DAY
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="previous_week"
                            onChange={onModeChange}
                            checked={overViewMode === "previous_week"}
                          />
                          PREVIOUS WEEK
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="previous_month"
                            onChange={onModeChange}
                            checked={overViewMode === "previous_month"}
                          />
                          PREVIOUS MONTH
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="previous_year"
                            onChange={onModeChange}
                            checked={overViewMode === "previous_year"}
                          />
                          PREVIOUS YEAR
                        </Label>
                      </div>
                    </UncontrolledCollapse>
                  </div>

                  <div>
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="toggler1"
                      type="button"
                    >
                      <h4>Compare Peer</h4>
                    </button>
                    <UncontrolledCollapse
                      toggler="#toggler1"
                      defaultOpen={true}
                    >
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="best"
                            onChange={onModeChange}
                            checked={overViewMode === "best"}
                          />
                          BEST PEER
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="average"
                            onChange={onModeChange}
                            checked={overViewMode === "average"}
                          />
                          PEER AVG
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="twin"
                            onChange={onModeChange}
                            checked={overViewMode === "twin"}
                          />
                          TWIN
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="all_peer"
                            onChange={onModeChange}
                            checked={overViewMode === "all_peer"}
                          />
                          ALL PEERS
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            name="mode"
                            id="exampleFormControlSelect1"
                            type="radio"
                            value="peer"
                          />
                          SELECT NODE
                          <Input
                            id="exampleFormControlSelect1"
                            type="select"
                            onChange={peerSelection}
                          >
                            {typeof peerList.peers != "undefined"
                              ? peerList.peers != null
                                ? Object.values(peerList.peers.meter_id).map(
                                    (item) => {
                                      return (
                                        <option key={item} value={item}>
                                          {meterMap[item]}
                                        </option>
                                      );
                                    },
                                  )
                                : null
                              : null}
                          </Input>
                        </Label>
                      </div>
                    </UncontrolledCollapse>
                  </div>
                  <br />
                  <Button className="btn btn-default" type="submit">
                    Submit
                  </Button>
                </FormGroup>
              </Form>

              <br />
            </ReactSlidingPane>
          ) : parentName === "Finance" ? (
            <ReactSlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class"
              isOpen={api.paneState}
              title="DASHBOARD"
              subtitle={<span style={{ color: "gray" }}>CUSTOMIZATION</span>}
              width="20rem"
              from="right"
              closeIcon={<span style={{ fontSize: "50px" }}>&#8250;</span>}
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                api.setPaneState(false);
              }}
              shouldCloseOnEsc
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  api.setPaneState(false);
                }}
              >
                <FormGroup>
                  <div>
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="toggler"
                      type="button"
                    >
                      <h4>Dashboard Unit</h4>
                    </button>
                    <UncontrolledCollapse toggler="#toggler" defaultOpen={true}>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input type="radio" name="mode" value="INR" />
                          INR
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input type="radio" name="mode" value="kWh" />
                          kWh
                        </Label>
                      </div>
                    </UncontrolledCollapse>
                  </div>
                  <Button className="btn btn-default" type="submit">
                    Submit
                  </Button>
                </FormGroup>
              </Form>
              <br />
            </ReactSlidingPane>
          ) : parentName === "Summary" ? (
            <ReactSlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class"
              isOpen={api.paneState}
              title="DASHBOARD"
              subtitle={<span style={{ color: "gray" }}>CUSTOMIZATION</span>}
              width="24rem"
              from="right"
              closeIcon={<span style={{ fontSize: "50px" }}>&#8250;</span>}
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                api.setPaneState(false);
              }}
              shouldCloseOnEsc
            >
              <Nav
                className=" nav-fill flex-column flex-sm-row"
                pills
                role="tablist"
              >
                <NavItem style={{ flex: 1, cursor: "pointer" }}>
                  <NavLink
                    aria-controls="tabs-text-1"
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    PAST DATE
                  </NavLink>
                </NavItem>
                <NavItem style={{ flex: 1, cursor: "pointer" }}>
                  <NavLink
                    aria-controls="tabs-text-1"
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    FUTURE DATE
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      tableModeChange("past");
                      api.setPaneState(false);
                    }}
                  >
                    <FormGroup>
                      <div>
                        <div
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "2rem",
                          }}
                        >
                          <h4>Select Past Date Range</h4>
                          <DatePickerCalendar
                            parenName="calendar"
                            day={selectedDayRange}
                            dayHandler={setSelectedDayRange}
                          />
                        </div>
                        <Button className="btn btn-default" type="submit">
                          Submit
                        </Button>
                      </div>
                    </FormGroup>
                  </Form>
                </TabPane>
                <TabPane tabId="2">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleFutureDayRange();
                      tableModeChange("future");
                      api.setPaneState(false);
                    }}
                  >
                    <FormGroup>
                      <div>
                        <div
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "2rem",
                          }}
                        >
                          <h4 style={{ textAlign: "right" }}>
                            Select Future Date Range
                          </h4>
                          <DatePickerCalendar
                            parenName="calendar"
                            day={selectedFutureDayRange}
                            dayHandler={setSelectedFutureDayRange}
                          />
                        </div>
                        <Button className="btn btn-default" type="submit">
                          Submit
                        </Button>
                      </div>
                    </FormGroup>
                  </Form>
                </TabPane>
              </TabContent>

              <hr />

              <br />
            </ReactSlidingPane>
          ) : parentName === "Audit" ? (
            <ReactSlidingPane
              className="some-custom-class right-pane"
              overlayClassName="some-custom-overlay-class"
              isOpen={api.paneState}
              title="Customize Dashboard"
              subtitle=""
              width="22rem"
              from="right"
              closeIcon=""
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                api.setPaneState(false);
              }}
              shouldCloseOnEsc
            >
              <div className="card-body au-report">
                {/*<Modalright activeTabId= {activeTabId} auditList = {auditList} auditList_id = {auditList_id}></Modalright>*/}
                <Modalright
                  //activeTabId={activeTabId}
                  auditList={auditList}
                  auditList_id={auditList_id}
                  report_title={report_title}
                  onSelectedAudit={selectedAudit}
                ></Modalright>
                <div className="d-flex justify-content-between mt-3 pt-1">
                  <p className="mb-0 font-reglar">Filter Noise</p>
                  <label className=" custom-toggle">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        e.stopPropagation();
                        noiseTicketsFilterAudit(e);
                      }}
                      checked={noiseTicketsAudit}
                    />
                    <span className="custom-toggle-slider rounded-circle"></span>
                  </label>
                </div>
                <hr className="my-3" />
                <div className="d-flex justify-content-between pt-1">
                  <p className="mb-0 font-reglar">Filter Closed</p>
                  <label className=" custom-toggle">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        e.stopPropagation();
                        closeTicketsFilterAudit(e);
                      }}
                      checked={closedTicketsAudit}
                    />
                    <span className="custom-toggle-slider rounded-circle"></span>
                  </label>
                </div>
              </div>
              {/*<Accordion activeTabId= {activeTabId} selectUnitOption = {selectfilterUnit} />*/}
              <Accordion
                //activeTabId={activeTabId}
                unitFilter={unitFilter}
                periodFilter={periodFilter}
                unit={unit}
                selectUnitOption={selectfilterUnit}
              />
            </ReactSlidingPane>
          ) : parentName === "Operations" ? (
            <ReactSlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class"
              isOpen={api.paneState}
              title="DASHBOARD"
              subtitle={<span style={{ color: "gray" }}>CUSTOMIZATION</span>}
              width="20rem"
              closeIcon={<span style={{ fontSize: "50px" }}>&#8250;</span>}
              from="right"
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                api.setPaneState(false);
              }}
              shouldCloseOnEsc
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  api.setPaneState(false);
                }}
              >
                <FormGroup>
                  <div>
                    <Row className="align-items-center">
                      <Col xl="8">
                        <p className="h4 mb-0">Filter Noise</p>
                      </Col>
                      <Col xl="4">
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              e.stopPropagation();
                              noiseTicketsFilter(e);
                            }}
                            checked={noiseTickets}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row className="align-items-center">
                      <Col xl="8">
                        <p className="h4 mb-0">Filter Closed</p>
                      </Col>
                      <Col xl="4">
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              e.stopPropagation();
                              closeTicketsFilter(e);
                            }}
                            checked={closedTickets}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div>
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="toggler"
                      type="button"
                    >
                      <p className="h4 mb-0">Dashboard Unit</p>
                    </button>
                    <UncontrolledCollapse toggler="#toggler" defaultOpen={true}>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="INR"
                            onChange={dataModeChange}
                          />
                          INR
                        </Label>
                      </div>
                      <div>
                        <Label
                          className="form-control-label"
                          style={{
                            marginLeft: "2rem",
                            padding: "0.1rem",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                          }}
                        >
                          <Input
                            type="radio"
                            name="mode"
                            value="kWh"
                            onChange={dataModeChange}
                          />
                          kWh
                        </Label>
                      </div>
                    </UncontrolledCollapse>
                  </div>
                  <Button className="btn btn-default" type="submit">
                    Submit
                  </Button>
                </FormGroup>
              </Form>
              <br />
            </ReactSlidingPane>
          ) : ["SummaryTableSingle", "Reports", "Rules"].includes(
              parentName,
            ) ? null : !isQuadrical ? null : (
            <ReactSlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class"
              isOpen={api.paneState}
              title="DASHBOARD"
              subtitle={<span style={{ color: "gray" }}>CUSTOMIZATION</span>}
              width="20rem"
              from="right"
              closeIcon={<span style={{ fontSize: "50px" }}>&#8250;</span>}
              onRequestClose={() => {
                // triggered on ">" on left top click or on outside click
                api.setPaneState(false);
              }}
              shouldCloseOnEsc
            >
              <Link href={"/manage/user"} passHref>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#152848",
                    width: "100%",
                    color: "white",
                  }}
                >
                  Manage Users
                </Button>
              </Link>
            </ReactSlidingPane>
          )}
        </>
      </Col>

      {parentName === "OverviewBatteries" ? (
        <div className="header  pb-1">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                {!["Audit"].includes(parentName) && (
                  <>
                    <Col lg="8" xs="8">
                      {["Rules", "EventsDetails"].includes(
                        parentName,
                      ) ? null : (
                        <Breadcrumb
                          className="d-none d-md-inline-block ml-md-4"
                          listClassName="breadcrumb-links breadcrumb-dark"
                        >
                          <BreadcrumbItem>
                            <a
                              href="/overview"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i
                                className="fas fa-home"
                                style={{ color: "black" }}
                              />
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a
                              style={{ color: "black" }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {name}
                            </a>
                          </BreadcrumbItem>
                          {["Reports", "EventsDetails"].includes(
                            parentName,
                          ) ? null : parentName != "Summary" &&
                            parentName != "SummaryTableSingle" &&
                            parentName != "plantAvailability" &&
                            parentName != "portfolio" ? (
                            <BreadcrumbItem>
                              {plantNameMap[plant]}
                            </BreadcrumbItem>
                          ) : null}
                          {[
                            "Reports",
                            "realtimeSnapshot",
                            "Availabilty",
                            "EventsDetails",
                          ].includes(parentName) ? null : [
                              "plantAvailability",
                              "Operations",
                            ].includes(parentName) ? (
                            <BreadcrumbItem>
                              {unixepochToDateString(ticketDate)}
                            </BreadcrumbItem>
                          ) : (
                            <BreadcrumbItem>
                              {unixepochToDateString(api.startDate)}
                              {" to "}
                              {unixepochToDateString(api.endDate)}
                            </BreadcrumbItem>
                          )}

                          {[
                            "plantAvailability",
                            "Operations",
                            "SummaryTableSingle",
                            "Summary",
                            "portfolio",
                            "MeterKPI",
                            "portfolio",
                            "EventsDetails",
                          ].includes(parentName) ? null : api.meterName !=
                            plantNameMap[api.plant_id] ? (
                            ["meterData"].includes(parentName) ? (
                              <BreadcrumbItem>
                                {api.meterTagDict[api.plant_id + "_" + meter]}
                              </BreadcrumbItem>
                            ) : (
                              <BreadcrumbItem>{api.meterName}</BreadcrumbItem>
                            )
                          ) : null}

                          {["meterDataXX", "meterDataNewXX"].includes(
                            parentName,
                          ) ? (
                            <MeterDataReadingChecks
                              handleClickCheckBox={handleClickCheckBox}
                              twinReading={twinReading}
                              globalReading={globalReading}
                              normalizedReading={normalizedReading}
                              derivedReading={derivedReading}
                              deviceState={deviceState}
                            />
                          ) : null}
                        </Breadcrumb>
                      )}
                    </Col>

                    <Col style={{ paddingLeft: "6.5rem" }}>
                      {setRefreshInterval == undefined ? null : (
                        <small suppressHydrationWarning>
                          Last Refresh: {lastRefresh}
                        </small>
                      )}

                      {setRefreshInterval == undefined ? null : (
                        <div xs="2" className={"float-right inline "}>
                          <Col>
                            <div className={"input-group input-group-sm"}>
                              <div className={"input-group-prepend"}>
                                <button
                                  onClick={handleIconClick}
                                  className={"input-group-text"}
                                  id="auto-refresh-dropdown"
                                >
                                  <i className="fa-solid fa-arrows-rotate"></i>
                                </button>
                              </div>
                              <select
                                id="afd"
                                aria-describedby="auto-refresh-dropdown"
                                className={"custom-select custom-select-sm "}
                                value={
                                  refreshIneterval === null
                                    ? "null"
                                    : refreshIneterval
                                }
                                onChange={setRefreshInterval}
                              >
                                <option value="null">Paused</option>
                                <option value={30000}>30 seconds</option>
                                <option value={60000}>1 minute</option>
                                <option value={300000}>5 minutes</option>
                                <option value={900000}>15 minutes</option>
                              </select>
                            </div>
                          </Col>
                        </div>
                      )}
                    </Col>
                  </>
                )}
              </Row>

              {parentName === "Overview" ? (
                <>
                  <Row>
                    <Col>
                      <OverviewPageCard item={powerCard} name="POWER" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={ghiCard} name="GHI TODAY" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={giiCard} name="GII TODAY" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={prCard} name="PR TODAY" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={energyCard} name="ENERGY TODAY" />
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </Container>
        </div>
      ) : (
        <div className="header  pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                {!["Audit"].includes(parentName) && (
                  <>
                    <Col lg="8" xs="8">
                      {["Rules"].includes(parentName) ? null : (
                        <Breadcrumb
                          className="d-none d-md-inline-block ml-md-4"
                          listClassName="breadcrumb-links breadcrumb-dark"
                        >
                          <BreadcrumbItem>
                            <a
                              href="/overview"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i
                                className="fas fa-home"
                                style={{ color: "black" }}
                              />
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <a
                              style={{ color: "black" }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {name}
                            </a>
                          </BreadcrumbItem>
                          {["Reports"].includes(
                            parentName,
                          ) ? null : parentName != "Summary" &&
                            parentName != "SummaryTableSingle" &&
                            parentName != "plantAvailability" &&
                            parentName != "portfolio" ? (
                            <BreadcrumbItem>
                              {plantNameMap[plant]}
                            </BreadcrumbItem>
                          ) : null}
                          {[
                            "Reports",
                            "realtimeSnapshot",
                            "Availabilty",
                          ].includes(parentName) ? null : [
                              "plantAvailability",
                              "Operations",
                            ].includes(parentName) ? (
                            <BreadcrumbItem>
                              {unixepochToDateString(ticketDate)}
                            </BreadcrumbItem>
                          ) : (
                            <BreadcrumbItem>
                              {unixepochToDateString(api.startDate)}
                              {" to "}
                              {unixepochToDateString(api.endDate)}
                            </BreadcrumbItem>
                          )}

                          {[
                            "plantAvailability",
                            "Operations",
                            "SummaryTableSingle",
                            "Summary",
                            "portfolio",
                            "MeterKPI",
                            "portfolio",
                          ].includes(parentName) ? null : api.meterName !=
                            plantNameMap[api.plant_id] ? (
                            ["meterData"].includes(parentName) ? (
                              <BreadcrumbItem>
                                {api.meterTagDict[api.plant_id + "_" + meter]}
                              </BreadcrumbItem>
                            ) : (
                              <BreadcrumbItem>{api.meterName}</BreadcrumbItem>
                            )
                          ) : null}

                          {["meterDataXX", "meterDataNewXX"].includes(
                            parentName,
                          ) ? (
                            <MeterDataReadingChecks
                              handleClickCheckBox={handleClickCheckBox}
                              twinReading={twinReading}
                              globalReading={globalReading}
                              normalizedReading={normalizedReading}
                              derivedReading={derivedReading}
                              deviceState={deviceState}
                            />
                          ) : null}
                        </Breadcrumb>
                      )}
                    </Col>

                    <Col style={{ paddingLeft: "6.5rem" }}>
                      {setRefreshInterval == undefined ? null : (
                        <small suppressHydrationWarning>
                          Last Refresh: {lastRefresh}
                        </small>
                      )}

                      {setRefreshInterval == undefined ? null : (
                        <div xs="2" className={"float-right inline "}>
                          <Col>
                            <div className={"input-group input-group-sm"}>
                              <div className={"input-group-prepend"}>
                                <button
                                  onClick={handleIconClick}
                                  className={"input-group-text"}
                                  id="auto-refresh-dropdown"
                                >
                                  <i className="fa-solid fa-arrows-rotate"></i>
                                </button>
                              </div>
                              <select
                                id="afd"
                                aria-describedby="auto-refresh-dropdown"
                                className={"custom-select custom-select-sm "}
                                value={
                                  refreshIneterval === null
                                    ? "null"
                                    : refreshIneterval
                                }
                                onChange={setRefreshInterval}
                              >
                                <option value="null">Paused</option>
                                <option value={30000}>30 seconds</option>
                                <option value={60000}>1 minute</option>
                                <option value={300000}>5 minutes</option>
                                <option value={900000}>15 minutes</option>
                              </select>
                            </div>
                          </Col>
                        </div>
                      )}
                    </Col>
                  </>
                )}
              </Row>

              {parentName === "Overview" ? (
                <>
                  <Row>
                    <Col>
                      <OverviewPageCard item={powerCard} name="POWER" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={ghiCard} name="GHI TODAY" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={giiCard} name="GII TODAY" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={prCard} name="PR TODAY" />
                    </Col>
                    <Col>
                      <OverviewPageCard item={energyCard} name="ENERGY TODAY" />
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </Container>
        </div>
      )}

      <style jsx>
        {`
          .nav-pills .nav-link.active,
          .nav-pills .show > .nav-link {
            background-color: #007bff;
          }
        `}
      </style>
      <Icons></Icons>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
